<template>
  <div class="flex">
    <svg width="55" height="147" viewBox="0 0 55 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_213750)">
        <path d="M0.5 72.2707L0.503082 72.2738L0.506164 72.2707L0.503082 72.2676L0.5 72.2707Z" fill="#EBFF00"/>
        <path d="M36.4365 72.2676L36.4396 72.2707L36.4427 72.2676H36.4365Z" fill="#EBFF00"/>
        <path d="M36.4287 72.2676L36.4346 72.2705L36.4375 72.2676H36.4287Z" fill="#EBFF00"/>
        <path d="M18.4688 72.2676L18.4746 72.2705L18.4776 72.2676H18.4688Z" fill="#EBFF00"/>
        <path d="M18.4629 72.2676L18.466 72.2707L18.4691 72.2676H18.4629Z" fill="#EBFF00"/>
        <path d="M27.5453 9.0293L18.5664 18.0631H36.5215L27.5453 9.0293Z" fill="#EBFF00"/>
        <path d="M18.5603 36.1328L9.57617 45.1693H27.5445L18.5603 36.1328Z" fill="#EBFF00"/>
        <path d="M54.5002 45.1693V36.1328L45.5107 45.1693H54.5002Z" fill="#EBFF00"/>
        <path d="M54.5002 27.1017V18.0625L45.5107 27.1017H54.5002Z" fill="#EBFF00"/>
        <path d="M27.5447 27.0984L27.5421 27.0957L18.5605 36.1322H36.5262L27.5473 27.0957L27.5447 27.0984Z"
              fill="#EBFF00"/>
        <path d="M45.5157 27.0984L45.513 27.0957L36.5342 36.1322H54.4998L45.5183 27.0957L45.5157 27.0984Z"
              fill="#EBFF00"/>
        <path d="M9.58474 27.0984L9.5821 27.0957L0.600586 36.1322H18.5662L9.58738 27.0957L9.58474 27.0984Z"
              fill="#EBFF00"/>
        <path d="M45.5106 45.1693L36.5264 36.1328L27.5449 45.1693H45.5106Z" fill="#EBFF00"/>
        <path d="M36.5215 54.2038L27.5453 45.1699L18.5664 54.2038H36.5215Z" fill="#EBFF00"/>
        <path d="M54.4766 54.2038L45.5004 45.1699L36.5215 54.2038H54.4766Z" fill="#EBFF00"/>
        <path d="M18.5606 54.2038L9.58433 45.1699L0.605469 54.2038H18.5606Z" fill="#EBFF00"/>
        <path d="M18.5638 54.2051L18.5612 54.2077L18.5585 54.2051L9.58496 63.2336H27.5401L18.5638 54.2051Z"
              fill="#EBFF00"/>
        <path d="M36.5234 54.2051L27.5498 63.2336H45.5049L36.5287 54.2051L36.526 54.2077L36.5234 54.2051Z"
              fill="#EBFF00"/>
        <path d="M0.592773 36.1328V45.1693H9.57692L0.592773 36.1328Z" fill="#EBFF00"/>
        <path d="M0.592773 18.0547V27.0938H9.57692L0.592773 18.0547Z" fill="#EBFF00"/>
        <path d="M36.5234 18.0645L27.5498 27.093H45.5049L36.5287 18.0645L36.526 18.0671L36.5234 18.0645Z"
              fill="#EBFF00"/>
        <path d="M18.5638 18.0645L18.5612 18.0671L18.5585 18.0645L9.58496 27.093H27.5401L18.5638 18.0645Z"
              fill="#EBFF00"/>
        <path d="M9.57359 9.03125L9.57095 9.03391L9.56831 9.03125L0.594727 18.0598H18.5498L9.57359 9.03125Z"
              fill="#EBFF00"/>
        <path d="M45.503 9.03125L45.5004 9.03391L45.4951 9.03125L36.5215 18.0598H54.4766L45.503 9.03125Z"
              fill="#EBFF00"/>
        <path d="M36.5185 0L27.5449 9.02853H45.5L36.5238 0L36.5211 0.00265779L36.5185 0Z" fill="#EBFF00"/>
        <path d="M18.5638 0L18.5612 0.00265779L18.5585 0L9.58496 9.02853H27.5401L18.5638 0Z" fill="#EBFF00"/>
        <path d="M27.5426 63.2363L18.5664 72.2675H36.5215L27.5479 63.2363L27.5453 63.239L27.5426 63.2363Z"
              fill="#EBFF00"/>
        <path d="M27.5479 84.1223L36.5215 75.0938H18.5664L27.5426 84.1223L27.5453 84.1196L27.5479 84.1223Z"
              fill="#EBFF00"/>
        <path d="M36.5264 54.2031L36.5322 54.2061L36.5352 54.2031H36.5264Z" fill="#EBFF00"/>
        <path d="M36.5215 54.2031L36.5246 54.2063L36.5276 54.2031H36.5215Z" fill="#EBFF00"/>
        <path d="M18.5605 54.2031L18.5636 54.2063L18.5667 54.2031H18.5605Z" fill="#EBFF00"/>
        <path d="M0.592773 54.2058L5.04654 54.2084L5.60921 54.2058V54.2031L0.592773 54.2058Z" fill="#EBFF00"/>
        <path d="M45.5049 27.0938L45.508 27.0969L45.511 27.0938H45.5049Z" fill="#EBFF00"/>
        <path d="M45.5107 27.0938L45.5138 27.0969L45.5169 27.0938H45.5107Z" fill="#EBFF00"/>
        <path d="M27.54 27.0938L27.5431 27.0969L27.5462 27.0938H27.54Z" fill="#EBFF00"/>
        <path d="M27.5449 27.0938L27.548 27.0969L27.5511 27.0938H27.5449Z" fill="#EBFF00"/>
        <path d="M9.57617 27.0938L9.58205 27.0967L9.58499 27.0938H9.57617Z" fill="#EBFF00"/>
        <path d="M9.57129 27.0938L9.57437 27.0969L9.57745 27.0938H9.57129Z" fill="#EBFF00"/>
        <path d="M36.5264 18.0625L36.5322 18.0655L36.5352 18.0625H36.5264Z" fill="#EBFF00"/>
        <path d="M36.5215 18.0625L36.5246 18.0656L36.5276 18.0625H36.5215Z" fill="#EBFF00"/>
        <path d="M18.5605 18.0625L18.5636 18.0656L18.5667 18.0625H18.5605Z" fill="#EBFF00"/>
        <path d="M18.5557 18.0625L18.5587 18.0656L18.5618 18.0625H18.5557Z" fill="#EBFF00"/>
        <path d="M18.5557 54.2031L18.5587 54.2063L18.5618 54.2031H18.5557Z" fill="#EBFF00"/>
        <path d="M45.5107 63.2344L45.5138 63.2375L45.5169 63.2344H45.5107Z" fill="#EBFF00"/>
        <path d="M45.5049 63.2344L45.508 63.2375L45.511 63.2344H45.5049Z" fill="#EBFF00"/>
        <path d="M27.5449 63.2344L27.548 63.2375L27.5511 63.2344H27.5449Z" fill="#EBFF00"/>
        <path d="M27.54 63.2344L27.5431 63.2375L27.5462 63.2344H27.54Z" fill="#EBFF00"/>
        <path d="M9.57617 63.2344L9.58205 63.2373L9.58499 63.2344H9.57617Z" fill="#EBFF00"/>
        <path d="M9.57129 63.2344L9.57437 63.2375L9.57745 63.2344H9.57129Z" fill="#EBFF00"/>
        <path d="M25.4103 86.4598L16.0986 77.0938L2.89844 106.364L25.4103 86.4598Z" fill="#EBFF00"/>
        <path d="M26.2527 89.4883L11.7925 103.867L10.6328 134.647L26.2527 89.4883Z" fill="#EBFF00"/>
        <path d="M29.7061 86.4598L39.0151 77.0938L52.2179 106.364L29.7061 86.4598Z" fill="#EBFF00"/>
        <path d="M28.8633 89.4883L43.3209 103.867L44.4832 134.647L28.8633 89.4883Z" fill="#EBFF00"/>
        <path d="M34.3361 120.647L27.4758 95.2598L20.7793 120.578L27.4758 146.999L34.3361 120.647Z" fill="#EBFF00"/>
      </g>
      <defs>
        <clipPath id="clip0_1_213750">
          <rect width="54" height="147" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script setup lang="ts">

</script>
