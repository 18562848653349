import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex bg-brand-lime rounded-full w-full h-14 font-medium text-xl text-brand-black" }
const _hoisted_2 = { class: "flex m-auto space-x-2.5" }
const _hoisted_3 = { class: "my-auto pt-0.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showSubscriptionPage && _ctx.showSubscriptionPage(...args))),
    class: "flex"
  }, [
    _renderSlot(_ctx.$slots, "body", {}, () => [
      _createElementVNode("button", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)
        ])
      ])
    ])
  ]))
}