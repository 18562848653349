import ApiServiceBase from "@/services/api/apiServiceBase";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {SocialFeedType} from "@/definitions/SocialFeedType";

export interface FeedServiceOptions {
  visibility?: PostPrivacy[]
}

export class ChangeLogService extends ApiServiceBase {

  public async get(version: string): Promise<any> {
    return this.http?.get(`/changelog?version=${version}`);
  }

}
