import ApiClient from "@/services/api/apiClient";
import {Preferences} from '@capacitor/preferences';
import {PreferenceKey} from "@/definitions/PreferenceKey";

export default class ApiServiceBase {

    public BASE_URL = process.env.VUE_APP_API;
    protected http: ApiClient | undefined;

    public constructor(headers = {}) {
        if (!this.http) {
            this.http = new ApiClient(headers);
            this.http.setBaseUrl(this.BASE_URL as string);
            this.http.registerTokenProvider(async () => {
                return await this.tokenProvider()
            })
            this.http.registerErrorHandler(async (response: any) => {
                await this.errorHandler(response)
            })

        }
    }

    public async tokenProvider() {
        try {
            const token = (await Preferences.get({key: PreferenceKey.ApiToken}))
            return token.value
        } catch (e) {
            await Promise.reject('Invalid API Token')
        }
    }

    public async errorHandler(response: any) {
        return Promise.reject(response)
    }

}
