import ApiServiceBase from "@/services/api/apiServiceBase";
import {isPlatform} from "@ionic/vue";
import {Preferences} from "@capacitor/preferences";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {PreferenceKey} from "@/definitions/PreferenceKey";
import {Glassfy} from "capacitor-plugin-glassfy";
import {DateTime} from "luxon";
import {UserInterface} from "@/interfaces/UserInterface";
import {StatusInterface} from "@/interfaces/StatusInterface";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";


export interface FriendsFeedServiceOptions {
    visibility?: PostPrivacy[]
}

export class FriendFeedService extends ApiServiceBase {

    private options = {
        visibility: [
            PostPrivacy.Public,
            PostPrivacy.InnerCircle,
            PostPrivacy.XRated,
        ]
    }

    constructor(options: FriendsFeedServiceOptions) {
        super();
        this.options = {...this.options, ...options}
    }

    public async getBefore(postId?: number): Promise<any> {
        return this.http?.get(`/friends/feed?before=${postId ? postId : ''}&without=comments&visibility=${this.options.visibility.join(',')}`);
    }

    public async getAfter(postId?: number): Promise<any> {
        return this.http?.get(`/friends/feed?after=${postId ? postId : ''}&without=comments&visibility=${this.options.visibility.join(',')}`);
    }

}
