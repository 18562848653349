import {AppUpdate, AppUpdateAvailability} from "@capawesome/capacitor-app-update";
import {modalController} from "@ionic/vue";
import PremiumSheet from "@/views/common/PremiumSheet.vue";
import AppUpdateAvailableSheet from "@/views/common/AppUpdateAvailableSheet.vue";

export function useAppUpdate() {

    const checkForUpdate = async () => {

        const result = await AppUpdate.getAppUpdateInfo();
        if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
            return;
        }

        if (!await modalController.getTop()) {
            const sheet = await modalController.create({
                breakpoints: [1],
                initialBreakpoint: 1,
                component: AppUpdateAvailableSheet,
                mode: 'ios',
                cssClass: 'update-available'
            })
            await sheet.present()
        }

    }

    const updateAvailable = async (appVersion: string) => {
        if (!await modalController.getTop()) {
            const sheet = await modalController.create({
                breakpoints: [1],
                initialBreakpoint: 1,
                component: AppUpdateAvailableSheet,
                mode: 'ios',
                cssClass: 'update-available',
                componentProps: {
                    appVersion
                }
            })
            await sheet.present()
        }
    }

    const download = async () => {
        await AppUpdate.openAppStore()
    }

    return {
        checkForUpdate,
        updateAvailable,
        download
    }

}