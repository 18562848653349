import ApiServiceBase from "@/services/api/apiServiceBase";
import {PostPrivacy} from "@/definitions/PostPrivacy";
import {FeedPostInterface} from "@/interfaces/FeedPostInterface";
import {SocialFeedType} from "@/definitions/SocialFeedType";

export interface FeedServiceOptions {
  visibility?: PostPrivacy[]
}

export class UserEventsService extends ApiServiceBase {

  public async getBefore(): Promise<any> {
    const paramsObj: any = {
      // before: '',
      // without: 'comments',
    };
    const params = new URLSearchParams(paramsObj);

    return this.http?.get(`/events?${params.toString()}`);
  }

  public async getAfter(eventId?: number): Promise<any> {
    const paramsObj: any = {
      after: eventId ? `${eventId}` : '',
      // without: 'comments'
    };
    const params = new URLSearchParams(paramsObj);

    return this.http?.get(`/events?${params.toString()}`);
  }

  public async get(eventId?: number): Promise<any> {
    return this.http?.get(`/events/${eventId}`);
  }

}
