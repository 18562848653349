import ApiService from "@/services/api/apiService";
import {alertController, modalController, toastController} from "@ionic/vue";
import {FriendUserInterface} from "@/interfaces/FriendUserInterface";
import {UserInterface} from "@/interfaces/UserInterface";
import FeedNewPostPage from "@/views/feed/FeedNewPostPage.vue";
import GalleryManagement from "@/views/user/gallery/GalleryManagement.vue";
import {DateTime} from "luxon";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

export function useUser() {

    const followUser = async (id: number) => {
        try {
            const user: UserBasicInformationInterface = await ApiService.User().followUser(id)
            const toast = await toastController.create({
                message: `You are now following ${user.name} 👀`,
                duration: 3000,
                position: 'bottom',
                color: 'primary'
            });
            await toast.present();
            return user
        } catch (e: any) {
            const toast = await toastController.create({
                message: e.data.message,
                duration: 3000,
                position: 'bottom',
                color: 'danger'
            });
            await toast.present();
        }
    }

    const unfollowUser = async (id: number) => {
        try {
            const user: UserBasicInformationInterface = await ApiService.User().unfollowUser(id)
            const toast = await toastController.create({
                message: `You are no longer following ${user.name}.`,
                duration: 3000,
                position: 'bottom',
                color: 'primary'
            });
            await toast.present();
            return user
        } catch (e: any) {
            const toast = await toastController.create({
                message: e.data.message,
                duration: 3000,
                position: 'bottom',
                color: 'danger'
            });
            await toast.present();
        }
    }


    const makeFriendRequest = async (id: number) => {
        try {
            const friend: FriendUserInterface = await ApiService.User().makeFriendRequest(id)
            const toast = await toastController.create({
                message: `You sent a friend request to ${friend.name} 🍍`,
                duration: 3000,
                position: 'bottom',
                color: 'primary'
            });
            await toast.present();
            return friend
        } catch (e: any) {
            const toast = await toastController.create({
                message: e.data.message,
                duration: 3000,
                position: 'bottom',
                color: 'danger'
            });
            await toast.present();
        }
    }

    const removeFriend = async (friend: FriendUserInterface, callback?:any) => {

        const alert = await alertController.create({
            header: 'Remove friend',
            message: `Are you sure you want to remove ${friend.name} from your friends list?`,
            buttons: [
                {
                    text: 'Cancel',
                    handler: async () => {
                        await alertController.dismiss(false)
                    }
                },
                {
                    text: 'Yes',
                    role: 'destructive',
                    handler:  async () => {
                        if (!friend) return
                        await alertController.dismiss(true)
                        await ApiService.User().removeFromFriends(friend.id).then(()=>{
                            if (callback) callback()
                        })
                    }
                },
            ]
        })
        await alert.present()
        const response = await alert.onWillDismiss()
        if (response.data) return true
        return Promise.reject()

    }

    const cancelFriendRequest = async (friend: FriendUserInterface, callback?:any) => {

        const alert = await alertController.create({
            header: 'Cancel request',
            message: `Are you sure you want to cancel your friend request with ${friend.name}?`,
            buttons: [
                {
                    text: 'Cancel',
                    handler: async () => {
                        await alertController.dismiss(false)
                    }
                },
                {
                    text: 'Yes',
                    role: 'destructive',
                    handler:  async () => {
                        if (!friend) return
                        await alertController.dismiss(true)
                        await ApiService.User().removeFromFriends(friend.id).then((user)=>{
                            if (callback) callback(user)
                        })
                    }
                },
            ]
        })
        await alert.present()
        const response = await alert.onWillDismiss()
        if (response.data) return true
        return Promise.reject()

    }


    const manageGallery = async () => {
        const modal = await modalController.create({
            component: GalleryManagement,
            mode: 'ios',
            presentingElement: document.getElementById('tabs') as HTMLElement,
        })
        await modal.present()
    }

    const age = (dob: string) => {
        return Math.floor(Math.abs(DateTime.fromFormat(dob as string, 'dd/MM/yyyy').diffNow('years').years))
    }

    const winkAtUser = async (userId: number, name: string) => {
        try {
            await ApiService.User().winkAtUser(userId)
            const toast = await toastController.create({
                message: `You winked at ${name} 😉`,
                duration: 3000,
                position: 'bottom',
                color: 'primary'
            });
            await toast.present();
        } catch (e:any) {
            toastController.create({
                message: e.data.message,
                duration: 3000,
                position: 'bottom',
                color: 'primary'
            }).then((toast)=>{
                toast.present()
            })
        }
    }

    const makePartnerRequest = async (id: number) => {
        try {
            const partner: UserBasicInformationInterface = await ApiService.User().makePartnerRequest(id)
            const toast = await toastController.create({
                message: `You sent a partner request to ${partner.name} 🫶`,
                duration: 3000,
                position: 'bottom',
                color: 'primary'
            });
            await toast.present();
            return partner
        } catch (e: any) {
            const toast = await toastController.create({
                message: e.data.message,
                duration: 3000,
                position: 'bottom',
                color: 'danger'
            });
            await toast.present();
        }
    }

    const removePartner = async (partner: UserBasicInformationInterface, callback?:any) => {

        const alert = await alertController.create({
            header: 'Remove friend',
            message: `Are you sure you want to end your partnership with <strong>${partner.name}</strong>?`,
            buttons: [
                {
                    text: 'Cancel',
                    handler: async () => {
                        await alertController.dismiss(false)
                    }
                },
                {
                    text: 'Yes',
                    role: 'destructive',
                    handler:  async () => {
                        if (!partner) return
                        await alertController.dismiss(true)
                        await ApiService.User().removeFromPartners(partner.id).then(()=>{
                            if (callback) callback()
                        })
                    }
                },
            ]
        })
        await alert.present()
        const response = await alert.onWillDismiss()
        if (response.data) return true
        return Promise.reject()

    }



    return {
        makeFriendRequest,
        removeFriend,
        makePartnerRequest,
        removePartner,
        manageGallery,
        age,
        winkAtUser,
        cancelFriendRequest,
        followUser,
        unfollowUser
    }

}