import ApiServiceBase from "@/services/api/apiServiceBase";
import {SearchUserInterface} from "@/interfaces/SearchUserInterface";
import {UserBasicInformationInterface} from "@/interfaces/UserBasicInformationInterface";

export class SearchService extends ApiServiceBase {

  public async users(query: string): Promise<UserBasicInformationInterface[]> {
    return this.http?.get('/search/users?query=' + encodeURIComponent(query));
  }

  public async chatUsers(query: string): Promise<UserBasicInformationInterface[]> {
    return this.http?.get('/search/chat/users?query=' + encodeURIComponent(query));
  }

  public async tags(tag: string, page = 1): Promise<any> {
    return this.http?.get(`/search/tags?tag=${tag}&page=${page}`);
  }

  public async nearby(lat: number, lng: number, radius = 20, type?: string): Promise<UserBasicInformationInterface[]> {
    return this.http?.get(`/search/nearby?lat=${lat}&lng=${lng}&radius=${radius}${type ? `&type=${type}` : ''}`);
  }


}
