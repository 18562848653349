import {modalController} from "@ionic/vue";
import PremiumSheet from "@/views/common/PremiumSheet.vue";

export function usePremium() {

    const presentPayWall = async (options?: any) => {

        const opts = {
            title: 'Join Pineapple Premium',
            text: 'Upgrade to unlock all premium features.',
            ... options
        }

        const sheet = await modalController.create({
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            component: PremiumSheet,
            componentProps: {
                title: opts.title,
                text: opts.text
            },
            mode: 'ios'
        })

        await sheet.present()
        await sheet.onWillDismiss()
        if (opts.callback) opts.callback()
    }

    return {
        presentPayWall
    }

}