import {modalController} from "@ionic/vue";
import VerificationRequiredSheet from "@/views/common/VerificationRequiredSheet.vue";

export function useVerification() {

    const presentVerificationRequired = async (text?: string) => {

        const props: any = {}

        if (text) props['text'] = text

        const sheet = await modalController.create({
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            component: VerificationRequiredSheet,
            componentProps: props,
            mode: 'ios',
        })
        await sheet.present()

    }

    return {
        presentVerificationRequired,
    }

}