import ApiServiceBase from "@/services/api/apiServiceBase";
import ApiService from "@/services/api/apiService";
import {Preferences} from "@capacitor/preferences";

export enum FeedPreference {
  Kinks = 'KINKS',
  Interests = 'INTERESTS',
  Gender = 'GENDER',
  Body = 'BODY',
  Drinking = 'DRINKING',
  Religion = 'RELIGION',
  Sexuality = 'SEXUALITY',
  Smoking = 'SMOKING',
  Piercings = 'PIERCINGS',
  Tattoos = 'TATTOOS',
  Relationship = 'RELATIONSHIP',
  Distance = 'DISTANCE',
}

export class FeedPreferencesService extends ApiServiceBase {

  private preferencesKey = 'FEED_PREFERENCES'

  public async set(key: FeedPreference, value: any): Promise<any> {
    await ApiService.User().setPreference(`FEED_PREFERENCES_${key}`, JSON.stringify(value))
    return await this.setPreferences()
  }

  private async setPreferences() {
    const preferences = await this.http?.get(`/feed/preferences`);
    await Preferences.set({key: this.preferencesKey, value: JSON.stringify(preferences)})
    return preferences
  }

  public async get(fresh = false): Promise<any> {
    if (fresh) return await this.setPreferences()

    const preferences = (await Preferences.get({key: this.preferencesKey})).value
    if (preferences) return JSON.parse(preferences)

    return this.setPreferences()
  }

}
