import ApiServiceBase from "@/services/api/apiServiceBase";
import {isPlatform} from "@ionic/vue";
import {Preferences} from "@capacitor/preferences";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {PreferenceKey} from "@/definitions/PreferenceKey";

export class SettingsService extends ApiServiceBase {

  public async getGenders(): Promise<any> {
    return this.http?.get('/settings/genders');
  }

  public async getSexualities(): Promise<any> {
    return this.http?.get('/settings/sexualities');
  }

  public async getDesires(): Promise<any> {
    return this.http?.get('/settings/desires');
  }

  public async getPronouns(): Promise<any> {
    return this.http?.get('/settings/pronouns');
  }

  public async getBodyTypes(): Promise<any> {
    return this.http?.get('/settings/body-types');
  }

  public async getSmokingTypes(): Promise<any> {
    return this.http?.get('/settings/smoking-types');
  }

  public async getDrinkingTypes(): Promise<any> {
    return this.http?.get('/settings/drinking-types');
  }

  public async getReligions(): Promise<any> {
    return this.http?.get('/settings/religions');
  }


}
