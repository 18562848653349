import {Loader} from "@googlemaps/js-api-loader";
import MapOptions = google.maps.MapOptions;
import LatLngLiteral = google.maps.LatLngLiteral;

export function useGoogleMaps() {

    const apiKey: string = process.env.VUE_APP_GOOGLE_MAPS_API_KEY as string

    const loadSDK = async () => {
        const loader = new Loader({
            apiKey,
            version: "weekly",
            libraries: ['places']
        });
        return loader.load()
    }

    const loadGoogleMaps = async (el: string, mapOptions: MapOptions = {}): Promise<{ googleMaps: typeof google.maps, map: google.maps.Map }> => {
        const google = await loadSDK()
        const map = new google.maps.Map(document.getElementById(el) as HTMLElement, {
            zoom: 8,
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false,
            keyboardShortcuts: false,
            disableDefaultUI: true,
            styles: [
              {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "saturation": 36
                  },
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 40
                  }
                ]
              },
              {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "visibility": "on"
                  },
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 16
                  }
                ]
              },
              {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 20
                  }
                ]
              },
              {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 17
                  },
                  {
                    "weight": 1.2
                  }
                ]
              },
              {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 20
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 21
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 17
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 29
                  },
                  {
                    "weight": 0.2
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 18
                  }
                ]
              },
              {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 16
                  }
                ]
              },
              {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 19
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#000000"
                  },
                  {
                    "lightness": 17
                  }
                ]
              }
            ],
            ...mapOptions
        })
        return Promise.resolve({googleMaps: google.maps, map})
    }

    const generateMapId = async (id?: string) => {
        return Promise.resolve('map' + new Date().getMilliseconds() + '_' + id)
    }

    const addMarker = async (map: google.maps.Map, position: LatLngLiteral) => {
        // markerIcon.anchor = new google.maps.Point(markerIcon.anchor.x, markerIcon.anchor.y)
        return new google.maps.Marker({
            position: position,
            map,
            // icon: markerIcon as google.maps.Icon
        });
    }

    return {
        loadSDK,
        loadGoogleMaps,
        generateMapId,
        addMarker
    }

}