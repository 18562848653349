import ApiServiceBase from "@/services/api/apiServiceBase";
import {UserService} from "@/services/api/user/userService";
import {SettingsService} from "@/services/api/settings/settingsService";
import {FeedService} from "@/services/api/feed/feedService";
import {ChatService} from "@/services/api/chat/chatService";
import {SearchService} from "@/services/api/search/searchService";
import {FriendFeedService} from "@/services/api/feed/friendFeedService";
import FeedPreferences from "@/views/feed/FeedPreferences.vue";
import {FeedPreferencesService} from "@/services/api/feed/feedPreferencesService";
import {SubscriptionService} from "@/services/api/subscription/subscriptionService";
import {EventsService} from "@/services/api/events/eventsService";
import {ChangeLogService} from "@/services/api/changelog/changelogService";
import {UserEventsService} from "@/services/api/user-events/userEventsService";

export default class ApiService extends ApiServiceBase {

    public static ChangeLog() {
        return new ChangeLogService()
    }

    public static UserEvents() {
        return new UserEventsService()
    }

    public static Events() {
        return new EventsService()
    }

    public static Feed() {
        return new FeedService()
    }

    public static FeedPreferences() {
        return new FeedPreferencesService()
    }

    public static FriendsFeed(options = {}) {
        return new FriendFeedService(options)
    }

    public static User() {
        return new UserService()
    }

    public static Notifications() {
        return new UserService()
    }

    public static Chat() {
        return new ChatService()
    }

    public static Settings() {
        return new SettingsService()
    }

    public static Search() {
        return new SearchService()
    }

    public static Subscription() {
        return new SubscriptionService()
    }

}
