import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera'

const useCamera = async (
  source: CameraSource = CameraSource.Camera,
  resultType: CameraResultType = CameraResultType.DataUrl,

): Promise<Photo> => {
  return Camera.getPhoto({
    source: source,
    resultType: resultType,
    quality: 85,
    presentationStyle: 'popover',
    width: 1200,
    height: 1200,
    correctOrientation: true
  })
    .then((cameraPhoto) => {
      return cameraPhoto
    })
    .catch((e) => {
      return e
    })
}

export default useCamera
