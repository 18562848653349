import {PushNotifications} from '@capacitor/push-notifications'
import {isPlatform, useIonRouter} from '@ionic/vue'
import {UserService} from "@/services/api/user/userService";
import {Observable, ObservableEvent} from "@/services/observable/Observable";
import {present} from "@ionic/core/dist/types/utils/overlays";
import {useNotification} from "@/composables/useNotification";
import ApiService from "@/services/api/apiService";
import {NotificationInterface} from "@/interfaces/NotificationInterface";

export class FcmService {
    public static async initPush(): Promise<any> {
        // Only run of device
        if (isPlatform('capacitor')) {
            const fcmService = new FcmService()
            await fcmService.registerPush()
        }
    }

    private async registerPush() {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting

        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            return
        }

        await PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', async (token: any) => {
            console.log('Push registration success, token: ' + token.value)
            const userService = new UserService()
            await userService.registerDeviceToken(token.value)
        })

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error on registration: ' + JSON.stringify(error))
        })

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: any) => {
                console.log('Push received: ' + JSON.stringify(notification))
                Observable.emit(ObservableEvent.NotificationsChanged)
            }
        )

        // Method called when tapping on a notification
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: any) => {
                console.log('Push action performed: ' + JSON.stringify(notification))
                const notificationContext = JSON.parse(notification.notification.data.context)
                const userNotification: NotificationInterface = await ApiService.User().getNotificationById(notificationContext.id)
                await useNotification().present(userNotification)
            }
        )

    }
}
